<template>
  <div class="justify-content-start view-container">
    <HeaderResidential />
  </div>
  <div class="justify-content-start w-100 view-container" style="margin-bottom: 50px">
    <div class="create_and_changeSection_btns">
      <div></div>
      <!-- <Button v-if="!loading"
        :appearance="classObjectCreatePQRS"
        text="Crear anuncio"
        color="primary"
        @clicked="openEditModal({})"
      /> -->

      <div class="changeSection">
        <div style="width: 50% !important; display: flex; justify-content: center; align-items: center">
          <Button v-if="!loading"
            :appearance="classObjectSection"
            :text="$t('pqrs.open')"
            :color="isOpen ? 'primary' : 'gray'"
            @click="() => {isOpen = true; currentPageOfPQRS = 1}"
          />
        </div>
        <div style="width: 50% !important; display: flex; justify-content: center; align-items: center">
          <Button v-if="!loading"
            :appearance="classObjectSection"
            :text="$t('pqrs.closed')"
            :color="!isOpen ? 'primary' : 'gray'"
            @click="() => {isOpen = false; currentPageOfPQRS = 1}"
          />
        </div>
      </div>
    </div>

    <div
      style="width: 100%; padding-right: 35px; ; margin-bottom: 100px"
    >
      <table v-if="!loading" class="ui blue unstackable selectable celled table"
       style="cursor: default !important; color: #808080; table-layout: fixed">
       <colgroup>
          <col style="width: 4%;" />
          <col style="width: 11%;" />
          <col style="width: 15%;" />
          <col style="width: 5%;" />
          <col style="width: 7%;" />
          <col style="width: 6%;" />
          <col />
          <col style="width: 11%;" />
          <col style="width: 11%;" />
          <col style="width: 5%;" />
          <col style="width: 10%;" />
          <col />
        </colgroup>
        <thead>
        <tr>
          <th v-for="header of pqrsHeaders" :key="header"
          style="text-align: center; color: #808080"
          :class="{'one wide':(header==='Activo' || header==='Destacado' || header==='')}">
            <div>
              {{header}}
            </div>
          </th>
        </tr>
        </thead>
        <tbody v-if="pqrsExist">
        <tr v-for="pqrs in pqrsData" :key="pqrs.id" :class="classObjectIsImportantRow(pqrs.important)">
          <td v-for="(value, field) in {
            id: pqrs.id,
            type: pqrs.type,
            name: pqrs.name,
            apartment: pqrs.apartment,
            message: '',
            imageUrl: pqrs.imageUrl,
            description: pqrs.description,
            createdAt: pqrs.createdAt,
            closeAt: pqrs.closeAt,
            edit: '',
            isOpen: pqrs.isOpen,
            important: pqrs.important
          }"
            :key="field"
            :class="{'CoverPicture':field === 'imageUrl' && value}"
            @click="(field === 'description' || field === 'type' || field === 'name' || field === 'apartment') ? openEditModal(pqrs):''"
          >
            <div v-if="field === 'important'" class="elementInTD">
              <i class="thumbtack icon" :class="classObjectIsImportant(value)" @click="changeImportance(pqrs, value)"></i>
            </div>
            <div v-else-if="field === 'message'" class="elementInTD">
              <router-link :to="{
                path: `/messaging/direct/PQRS%20${pqrs.id}`,
                }"
                :data-tooltip="'Enviar mensaje'"
                data-position="bottom center"
                style="color: var(--messaging-color)"
                @click.prevent="sendMessage({
                  id: pqrs.userId,
                  apartment: pqrs.apartment,
                  firstName: pqrs.name,
                  lastName: '',
                  avatar: '',
                  building: residential.name
                })"
              >
                <i class="envelope icon"></i>
              </router-link>
            </div>
            <div v-else-if="field === 'imageUrl' && value.length == 0" class="noCoverPicture elementInTD">
              <!-- <i class="image outline icon" style="margin: 0"></i> -->
            </div>
            <div v-else-if="field === 'createdAt' && value" class="noCoverPicture elementInTD" style="font-size: 14px">
              {{ getFormattedCreatedDate(value) }}
            </div>
            <div v-else-if="field === 'createdAt' && !value" class="noCoverPicture elementInTD" style="font-size: 14px">
              {{$t('pqrs.noDate')}}
            </div>
            <div v-else-if="field === 'imageUrl' && value" class="elementInTD">
              <div v-if="verifyImage(value)">
                <img :src="getFirstImage(value)" alt="" class="coverImage" @click="openCoverPicModal(getFirstImage(value))">
              </div>
              <div v-if="value && value.length !== 0">
                <div v-if="verifypdf(value)" style="margin: 0px 5px;">
                  <span style="display:flex; justify-content: center" v-html="formatFilesInfo(value)"></span>
                </div>
              </div>
            </div>
            <div v-else-if="field === 'isOpen'" class="ui toggle checkbox pqrs" style="width: 100%; display: flex; justify-content: center;" @click="changeActivation(pqrs)">
              <input type="checkbox" :v-model="pqrs.isOpen" :checked="pqrs.isOpen" @change="changeActivation(pqrs)">
              <label style="height: 0px; width:0px; margin-top: 7px"></label>
            </div>
            <div v-else-if="field === 'link'  && value" class="pqrsLink elementInTD" style="text-decoration: none">
              <a :href="value" target="_blank"><i class="linkify icon"></i></a>
            </div>
            <div v-else-if="field === 'edit'" class="elementInTD">
              <i class="pen icon editPen" @click="openEditModal(pqrs)"></i>
            </div>
            <div v-else-if="field === 'apartment'" class="elementInTD" style="text-decoration: none">
              {{value}}
            </div>
            <div v-else-if="field === 'description'" class="descriptionField">
              {{value}}
            </div>
            <div v-else-if="field === 'type'" class="titleField">
              {{getCategory(value)}}
            </div>
            <div v-else-if="field === 'closeAt' && value" class="noCoverPicture elementInTD" style="font-size: 14px">
              {{ getFormattedCreatedDate(value) }}
            </div>
            <div v-else-if="field === 'closeAt' && !value && pqrs.isOpen" class="noCoverPicture elementInTD" style="font-size: 14px">
              -
            </div>
            <div v-else>
              {{ value }}
            </div>
          </td>
        </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="10" class="text-center">
              {{$t('pqrs.noPQRSFound')}}
            </td>
          </tr>
        </tbody>

        <tfoot v-if="pqrsExist" style="color: #808080;">
          <tr>
            <th colspan="10" style="border: none">
              <div class="ui right floated pagination menu">
                <a class="icon item" style="color: #808080;" @click="prevPageOfPQRS">
                  <i class="left chevron icon"></i>
                </a>
                <a
                  :class="classActivationStatusPage(numPagOfPQRS)"
                  class="item" style="color: #808080"
                  v-for="numPagOfPQRS in numberOfPagesOfPQRSInSection"
                  :key="numPagOfPQRS"
                  @click="changePageOfPQRS(numPagOfPQRS)"
                  >{{numPagOfPQRS}}</a>
                <a class="icon item" @click="nextPageOfPQRS">
                  <i class="right chevron icon" style="color: #808080;"></i>
                </a>
              </div>
            </th>
        </tr>
      </tfoot>
      </table>
    </div>
    <PQRSModal
      :show="isEditingPQRS"
      :edit_data="modalData"
      :isFullOfImportant="isFullOfImportantPQRS"
      @close="closePQRSEditModal"
    />
    <CoverPicOfPQRSModal
      :show="isShowingCoverPic"
      :edit_data="coverPicModalData"
      @close="closeCoverPicModal"
    />
  </div>
</template>

<script>

// import { SuiModal, SuiModalContent, SuiModaldescription } from 'vue-fomantic-ui'
import HeaderResidential from '@/components/ui/HeaderResidential'
import PQRSModal from '@/components/modals/PQRSModal'
import CoverPicOfPQRSModal from '@/components/modals/CoverPicOfPQRSModal'
import Button from '@/components/form/Button'
import { mapActions, mapGetters } from 'vuex'
import { changePQRSImportance as updateImportance, changePQRSActivationStatus } from '@/api/pqrs'
// import { SuiCheckbox } from 'vue-fomantic-ui'

export default {
  components: {
    HeaderResidential,
    PQRSModal,
    Button,
    CoverPicOfPQRSModal
    // SuiCheckbox
  },
  // created () {
  //   if (this.items) {
  //     this.items = []
  //     // console.log('there are items')
  //   }
  // },
  beforeMount () {
    this.loading = true
    this.setLoading(true)
    this.loadPQRS(this.residential.residentialId)
      .then((r) => {
        // this.loading = false
        // console.log('bulletins loaded')
      }).catch((error) => {
        // this.loading = false
        console.log('error cargando pqrs', error)
      }).finally(() => {
        this.setLoading(false)
        this.loading = false
      })
  },
  // updated () {
  //   console.log('current page of bulletin is ', this.currentPageOfPQRS)
  // },
  data () {
    return {
      loading: false,
      isEditingPQRS: false,
      isShowingCoverPic: false,
      numOfImportantPQRS: 3,
      pqrsHeaders: [
        this.$t('pqrs.headers.id'),
        this.$t('pqrs.headers.category'),
        this.$t('pqrs.headers.name'),
        this.$t('pqrs.headers.unit'),
        this.$t('pqrs.headers.respond'),
        this.$t('pqrs.headers.attachements'),
        this.$t('pqrs.headers.description'),
        this.$t('pqrs.headers.reportDate'),
        this.$t('pqrs.headers.closingDate'),
        this.$t('pqrs.headers.edit'),
        this.$t('pqrs.headers.closeOpen'),
        this.$t('pqrs.headers.highlighted')
      ],
      modalData: {},
      coverPicModalData: null,
      isOpen: true,
      maxPQRSPerPageOfPQRS: 10,
      currentPageOfPQRS: 1
    }
  },

  computed: {

    numberOfPQRSInSection () {
      const section = this.isOpen
      return this.allPQRS.filter(pqrs => pqrs.isOpen === section).length
    },

    numberOfPagesOfPQRSInSection () {
      if (this.numberOfPQRSInSection <= this.maxPQRSPerPageOfPQRS) {
        return 1
      }
      return Math.ceil(this.numberOfPQRSInSection / this.maxPQRSPerPageOfPQRS)
    },

    pqrsExist () {
      // console.log('Exist?', this.pqrsData.length)
      // console.log('Loading?', this.loading)
      return this.pqrsData.length !== 0
    },

    residential () {
      return this.$store.getters['residential/activeResidential']
    },

    pqrsData () {
      const section = this.isOpen
      let pqrsToShow = this.allPQRS

      pqrsToShow = pqrsToShow
        .filter(pqrs => pqrs.isOpen === section)

      // getting 2 arrays, important and the others
      const importantPQRS = []; const nonImportantPQRS = []

      pqrsToShow.forEach(pqrs => {
        // console.log('first forEach loop', pqrs)
        if (pqrs.important) {
          importantPQRS.push(pqrs)
        } else {
          nonImportantPQRS.push(pqrs)
        }
      })

      // Sorting pqrs visually by date
      importantPQRS.sort(function (pqrsA, pqrsB) {
        const dateA = new Date(pqrsA.createdAt)
        const dateB = new Date(pqrsB.createdAt)
        // console.log(dateA, dateB)
        return dateB - dateA
      })

      nonImportantPQRS.sort(function (pqrsA, pqrsB) {
        const dateA = new Date(pqrsA.createdAt)
        const dateB = new Date(pqrsB.createdAt)
        // console.log(dateA, dateB)
        return dateB - dateA
      })

      importantPQRS.push(...nonImportantPQRS)
      pqrsToShow = importantPQRS

      const lowerIdx = this.maxPQRSPerPageOfPQRS * (this.currentPageOfPQRS - 1)
      const upperIdx = this.currentPageOfPQRS < this.numberOfPagesOfPQRSInSection ? lowerIdx + this.maxPQRSPerPageOfPQRS : this.numberOfPQRSInSection
      return pqrsToShow.slice(lowerIdx, upperIdx)
    },

    classObjectCreatePQRS () {
      return {
        /* 'margin-top': '25px',
        'margin-bottom': '20px', */
        height: '29px',
        with: '100px',
        display: 'flex',
        'justify-content': 'space-between',
        'align-items': 'center'
        /* padding: '7px 2.4rem !important' */
      }
    },

    classObjectSection () {
      return {
        height: '29px !important',
        width: '100% !important',
        display: 'flex',
        'justify-content': 'space-between',
        'align-items': 'center'
      }
    },

    ...mapGetters('pqrs', [
      'allPQRS',
      'pqrsById'
    ]),

    currentNumberOfImportantPQRS () {
      return this.allPQRS.filter(pqrs => pqrs.important).length
    },

    isFullOfImportantPQRS () {
      return !(this.currentNumberOfImportantPQRS < this.numOfImportantPQRS)
    }
  },
  methods: {
    ...mapActions('loading', ['setLoading']),
    ...mapActions('messaging', ['setResidentialMembersToMessage']),

    sendMessage (resident) {
      this.setResidentialMembersToMessage([resident])
    },
    classActivationStatusPage (numPagOfPQRS) {
      return {
        activePage: numPagOfPQRS === this.currentPageOfPQRS
      }
    },

    prevPageOfPQRS () {
      if (this.currentPageOfPQRS !== 1) {
        this.currentPageOfPQRS -= 1
      }
    },

    nextPageOfPQRS () {
      if (this.currentPageOfPQRS !== this.numberOfPagesOfPQRSInSection) {
        this.currentPageOfPQRS += 1
      }
    },

    changePageOfPQRS (numPagOfPQRS) {
      this.currentPageOfPQRS = numPagOfPQRS
      // console.log('Current page of bulletin is ', this.currentPageOfPQRS)
    },

    openEditModal (pqrs) {
      this.modalData = pqrs
      this.isEditingPQRS = true
    },

    openCoverPicModal (pic) {
      // console.log('picture selected', pic)
      this.coverPicModalData = pic
      this.isShowingCoverPic = true
    },

    closeCoverPicModal () {
      this.isShowingCoverPic = false
      this.coverPicModalData = null
    },

    closePQRSEditModal () {
      // console.log('emit close modal call')
      this.isEditingPQRS = false
      this.modalData = {}
    },

    ...mapActions('pqrs', [
      'loadPQRS',
      'update_PQRS'
    ]),

    classObjectIsImportant (isImportant) {
      return {
        importantThumbtack: isImportant,
        nonImportantThumbtack: !isImportant
      }
    },

    classObjectIsImportantRow (isImportant) {
      return {
        blue: isImportant,
        nonImportantRow: !isImportant
      }
    },

    getFirstImage (attachment) {
      if (attachment && attachment.length > 0) {
        return attachment.filter((value) => (value.includes('.jpg') || value.includes('.png')))[0]
      } else {
        return []
      }
    },

    getFirstPdf (attachment) {
      if (attachment && attachment.length > 0) {
        return attachment.filter((value) => (value.includes('.pdf')))[0]
      } else {
        return []
      }
    },

    changeImportance (pqrs, isImportantComing) {
      if (!pqrs.isOpen) {
        this.$swal(this.$t('errors.pqrs.shouldBeOpenedFirst'), '', 'error')
        return
      }

      if (this.isFullOfImportantPQRS && !isImportantComing && pqrs.isOpen) {
        this.$swal(this.$t('errors.pqrs.maxHighlighted'), '', 'error')
      } else {
        // this.isEditingOnTable = true
        const id = pqrs.id
        const { type, imageUrl, description, isOpen, important, closeAt, createdAt, name, apartment } = pqrs
        const updatedPQRS = {
          id,
          important: !important,
          type,
          imageUrl,
          description,
          createdAt,
          isOpen,
          closeAt,
          name,
          apartment
        }

        this.setLoading(true)

        updateImportance(id, { isImportant: updatedPQRS.important }).then(() => {
          // this.isEditingOnTable = false
          this.update_PQRS(updatedPQRS)
          // console.log('importance changed')
        }).catch(error => {
          console.log(error)
          // this.isEditingOnTable = false
          this.$swal(this.$t('errors.somethingHappened'), '', 'error')
        }).finally(() => {
          this.setLoading(false)
        })
      }
    },

    changeActivation (pqrs) {
      // console.log('Change Open')
      const id = pqrs.id
      const { type, imageUrl, description, isOpen, createdAt, closeAt } = pqrs
      const updatedPQRS = {
        id,
        important: false,
        type,
        imageUrl,
        description,
        createdAt,
        closeAt,
        isOpen: !isOpen,
        apartment: pqrs.apartment,
        name: pqrs.name
      }

      this.setLoading(true)
      changePQRSActivationStatus(id, { isOpen: updatedPQRS.isOpen }).then(() => {
        // this.isEditingOnTable = false
        this.update_PQRS(updatedPQRS)
      }).catch(error => {
        console.log(error)
        // this.isEditingOnTable = false
        this.$swal(this.$t('errors.somethingHappened'), '', 'error')
      }).finally(() => {
        this.setLoading(false)
      })
    },

    getFormattedCreatedDate (pqrsCreatedAt) {
      const createdDate = new Date(pqrsCreatedAt)
      let day = createdDate.getDate()
      day = day < 10 ? `0${day}` : `${day}`
      let month = createdDate.getMonth() + 1
      month = month < 10 ? `0${month}` : `${month}`
      return `${day}/${month}/${createdDate.getFullYear()}`
    },

    verifypdf (listAttachments) {
      if (listAttachments) {
        return listAttachments.reduce((pre, value) => {
          if (pre) return pre
          return (value) ? value.includes('.pdf') : pre
        }, false)
      } else {
        return false
      }
    },

    verifyImage (listAttachments) {
      if (listAttachments) {
        return listAttachments.reduce((pre, value) => {
          if (pre) return pre
          return (value) ? (value.includes('.jpg') || value.includes('.png')) : pre
        }, false)
      } else {
        return false
      }
    },

    formatFilesInfo (value) {
      // const attachmentIcon = '<i class="paperclip icon"></i>'
      const file = this.getFirstPdf(value)
      return `<a href="${file}"><i class="paperclip icon" style="color: #2185d0"></i></a>`
    },

    getCategory (value) {
      switch (value) {
        case 1:
          return this.$t('pqrs.categories.harm')
        case 2:
          return this.$t('pqrs.categories.faults')
        case 3:
          return this.$t('pqrs.categories.complaint')
        case 4:
          return this.$t('pqrs.categories.claim')
        case 5:
          return this.$t('pqrs.categories.other')
        default:
          return this.$t('pqrs.categories.other')
      }
    }
  }
}
</script>

<style scoped>
  .descriptionField {
    /* background-color: red !important; */
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    /* max-width: inherit !important; */
    white-space: nowrap;
  }

    .view-container {
        text-align: left;
        margin-left: 30px;
        margin-top: 17px;
        /*height: 90vh;*/
    }

    .pqrsLink {
      color: #808080 !important;
    }

    .importantThumbtack, .nonImportantThumbtack, .noCoverPicture {
      color: #808080;
      font-size: 20px;
    }

    .importantThumbtack {
      color: rgb(235, 36, 36);
      /* transform: rotate(-45deg); */
      transform: rotate(0deg);
      transition: ease .3s
    }

    /* .importantThumbtack:hover {
      color: #808080;
      transform: rotate(-45deg)
    } */

    .noCoverPicture {
      color: #808080
    }

    .nonImportantThumbtack {
      color: #808080;
      transform: rotate(-45deg);
      transition: ease .3s
    }

    /*.nonImportantThumbtack:hover {
      color: rgb(235, 36, 36);
      transform: rotate(0);
    } */

    .editPen {
      font-size: 15px;
      transition: ease .3s
    }

    .editPen:hover {
      transform: scale(1.2);
    }

    .CoverPicture {
      padding: 0 !important;
    }
    .coverImage {
      height: 30px;
      width: auto
    }
    .elementInTD {
      display:flex;
      justify-content: center;
      align-items: center;
    }

    .nonImportantRow {
      color: #808080;
    }

    .nonImportantRow:hover {
      color: #808080 !important;
    }

    /* .sectionActive {
      background-color: #436f98 !important;
      color: white !important;
      transition: background-color .5s ease !important;
    }
    .sectionActive:hover {
      background-color: #2c97c5 !important;
    } */
    .create_and_changeSection_btns {
      display: flex;
      width: 100%;
      height: 60px;
      justify-content: space-between;
      align-items: center;
      padding-right: 35px;
    }

    .changeSection {
      display: flex;
    }

    .activePage {
  background-color: #436f98 !important;
  color: white !important;
    }
</style>
